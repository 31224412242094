import { connect } from "react-redux";
// components
import HomePageHelmet from "../components/HomePageHelmet";
// helpers + selectors
import { getSEODetailsForCityHomepage } from "../selectors/seo";
import { IMAGE_URLS } from "../helpers/constants";
import { getFeaturedData, getFeaturedStayInData, getPhysicalEvents, getSelectedCityName } from "../selectors";
import { getDisplayMode } from "../selectors/homepage";
const mapStateToProps = (state, props) => {
    const { title, metaProps } = getSEODetailsForCityHomepage(state, props);
    const selectedCity = getSelectedCityName(state);
    const featuredEventsData = getDisplayMode(state) === "go-out" ? getFeaturedData(state) : getFeaturedStayInData(state);
    const physicalEventsData = getPhysicalEvents(state);
    return {
        // TODO make this a per instance memoized selector
        title,
        meta: metaProps,
        selectedCity,
        featuredEventsData,
        physicalEventsData
    };
};
const HomePageHelmetWithSubscription = connect(mapStateToProps)(HomePageHelmet);
HomePageHelmetWithSubscription.defaultProps = {
    keywords: "food, movie, music, travel",
    ogImageWidth: 1200,
    ogImageHeight: 650,
    twitterCard: "summary",
    title: "Upcoming events in ${city} - Paytm Insider",
    description: "Handpicked Collection of the best events including Live Comedy, Music, Food, Exhibitions, Festivals, Workshops & Travel.",
    ogImage: IMAGE_URLS.HELMET
};
export default HomePageHelmetWithSubscription;
