import React from "react";
import { InsiderIconWithGreyBg } from "../../components/ui/icons/InsiderIconWithGreyBg";
import { Music } from "../../components/ui/icons/CategoryIcons/Music";
import { Experiences } from "../../components/ui/icons/CategoryIcons/Experiences";
import { Cricket } from "../../components/ui/icons/CategoryIcons/Cricket";
/**
 * @type {Link}
 * @property {string} resource_type - The type of resource, possible values are: "tag", "event", "article", "category", "external", "group", "go" and "filtered_list"
 * @property {string} [title] - The title of the link.
 * @property {string} [slug] - The slug for the link.
 * @property {string} [url] - The URL of the link (applicable for "external" resource_type).
 * @property {Object} [query_params] - Additional query parameters for the link (applicable for "filtered_list" resource_type).
 */
/**
 * @type {IIconCard}
 * @property {string} title - The title of the quick link.
 * @property {string} subtitle - The subtitle associated with the quick link.
 * @property {string} image - The URL of the image associated with the quick link.
 * @property {string[]} [includeCities] - An array of cities to include for the quick link. Add ["global"] for adding it in all cities. includeCities takes priority over excludeCities
 * @property {string[]} [excludeCities] - An array of cities to exclude for the quick link.
 * @property {Link} link - The object containing params to construct the links
 * @property {boolean} openInNewTab - Boolean set to true to open links in new tab
 */
/**
 * Configuration for the homepage UI components.
 * @type {IIconCard[]}
 */
// this is a temporary config to power the homepage ui components untill the admin flow is built
// note: if more than 3 quicklinks qualify for a city, they will be shuffled
export const quickLinks = [
    //Mumbai
    {
        title: "Kya Baat hai!",
        subtitle: "Sufi Music, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724416689/f0fedcudj3swub6eox0j.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "rehmatenusrat-an-evening-of-qawwalis-ghazals-and-sufi-music-sep5-2024"
        }
    },
    {
        title: "Open Couple",
        subtitle: "Next on the 'Manch'",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724416730/zotlvcxk8qk1vyinpprm.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "the-open-couple"
        }
    },
    {
        title: "All about culture",
        subtitle: "Culture Con 2024",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724416779/deokb3fuwkooq6arx0s7.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "culturecon-2024-an-indian-art-culture-conference-aug29-2024"
        }
    },
    //Delhi
    {
        title: "T20 Cricket",
        subtitle: "Get tickets to DPL '24",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723867688/kbknnlmt6mt8cb7ysuuv.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "article",
            slug: "delhi-premier-league-2024"
        }
    },
    {
        title: "Jazz things up!",
        subtitle: "Jazz Weekender 2024",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723867720/lcu0hfqcxyauysdlavxd.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "jazz-weekender-2024-international-jazz-festival"
        }
    },
    {
        title: "South Culture",
        subtitle: "South Comes To North",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1715581200/vht2je7eelwl2jl6cbeu.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "south-side-story-2024-aug31-2024"
        }
    },
    //Bengaluru
    {
        title: "Greatest hits",
        subtitle: "Geetha Madhuri, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723868061/ztjukfgg7zmd7p0hyd0w.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "geetha-madhuri-live-at-fandom"
        }
    },
    {
        title: "This December!",
        subtitle: "Echoes of Earth 2024",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723436709/hqljpchmapfeoiz0tvis.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "echoesofearth2024bangalore"
        }
    },
    {
        title: "Soul Sante Flea",
        subtitle: "Boholove Edition",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724416929/wdt6ogyaxia9ohgksthu.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "sunday-soul-sante-boholove-edition-sep22-2024"
        }
    },
    //Pune
    {
        title: "Roadtrip to Leh",
        subtitle: "The Outback Festival",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723436938/iqnb26e8dx0fos3lmobk.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "the-outback-festival-2024-sep14-2024"
        }
    },
    {
        title: "Punyakoti",
        subtitle: "By Sonu Venugopal",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724419647/smvcozoueyteemvo5usx.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "punyakoti-a-digital-standup-comedy-special-by-sonu-venugopal"
        }
    },
    {
        title: "Get. set. Garba!",
        subtitle: "Navratri 2024",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724038696/hkdgnwdruvtwhg9fkzfz.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "dandio-ki-raas-leela-navratri-2024"
        }
    },
    //Goa
    {
        title: "GROSSOMODDO",
        subtitle: "Sept 6th at Raeeth",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724419673/lugrml9yoxlnhq6ulgnm.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "paradox-x-raeeth-presents-grossomoddo-goa-sep6-2024"
        }
    },
    {
        title: "Next @ Raaeth",
        subtitle: "P.O.U on Aug 30th!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724038724/ldjrlfcptwy65xltmh0l.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "raeeth-presents-pou-friday-aug30-2024"
        }
    },
    {
        title: "Techno Logic",
        subtitle: "House of Chapora",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724419705/pb8prwdtoqw2ne7ospfz.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "techno-logic-100-aug10-2024"
        }
    },
    //Hyderabad
    {
        title: "For the first time",
        subtitle: "Armaan Malik, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470692/uh6vy4ngghjfxyx7idam.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Top Lesi Poddi",
        subtitle: "Rockstar DSP, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039096/yn8obun5ohhi2e2albrt.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        title: "Jam Junxion",
        subtitle: "Hyderabad, Sept 6th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437245/dx4lt6la7kw3i88r41dd.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "jam-junxion-sep6-2024"
        }
    },
    //Chennai
    {
        title: "Lights out!",
        subtitle: "Indian Racing Fest",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724038864/dwtk9m8aquav0rorvonh.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "indian-racing-festival-2024-chennai-formula-racing-circuit"
        }
    },
    {
        title: "Hip Hop Machi",
        subtitle: "LIVE on Sept 21st",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724420080/awlc4fhiu7dtst8ivttd.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "return-of-the-dragon-machi-home-edition-sep21-2024-chennai"
        }
    },
    {
        title: "Voice of Chennai",
        subtitle: "Season 1's Grand Finale",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724420041/emut0me18tktvwdx1ydj.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "grand-finale-voice-of-chennai-sep1-2024"
        }
    },
    //Kolkata
    {
        title: "Heartbreak 2020",
        subtitle: "Kamakshi on tour!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039053/bixejh7ddcx85pawdmig.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "kamakshi-khanna-heartbreak-2020-tour-kolkata-sep4-2024"
        }
    },
    {
        title: "Good Luck Beta",
        subtitle: "PCRC on Tour",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1722015523/qoqt8pxhumocrps0wnhs.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-kolkata-dec29-2024"
        }
    },
    {
        title: "Vishal Mishra, LIVE!",
        subtitle: "On Sept 28th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724554882/zcyxin200sf4t7lotpmq.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "vishal-mishra-kolkata-sep28-2024"
        }
    },
    //chandigarh
    // {
    // 	title: "Ek Dil Do Yaar",
    // 	subtitle: "Ahen Vatish LIVE",
    // 	image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437277/z14jffki83bnjcugrfqh.jpg",
    // 	includeCities: ["Chandigarh"],
    // 	link: {
    // 		resource_type: "event",
    // 		slug: "ek-dil-do-yaar-ahen-aug24-2024"
    // 	}
    // },
    // {
    // 	title: "Amit Tandon, LIVE",
    // 	subtitle: "August 23rd",
    // 	image: "https://media.insider.in/image/upload/c_crop,g_custom/v1722015759/slixcj7dvwcrcd9fzozs.jpg",
    // 	includeCities: ["Chandigarh"],
    // 	link: {
    // 		resource_type: "event",
    // 		slug: "punchliners-comedy-show-ft-amit-tandon-live-aug23-2024"
    // 	}
    // },
    // {
    // 	title: "Shaadi Mubarak",
    // 	subtitle: "With Chirag Panjwani",
    // 	image: "https://media.insider.in/image/upload/c_crop,g_custom/v1722743621/jbd7g4dhuzqwoblkkygo.jpg",
    // 	includeCities: ["Chandigarh"],
    // 	link: {
    // 		resource_type: "event",
    // 		slug: "shaadi-mubarak-comedy-show-ft-chirag-panjwani-aug24-2024"
    // 	}
    // },
    //coimbatore
    {
        title: "Hip Hop Tamizha",
        subtitle: "LIVE on Sep 8th!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1722743394/lin4tl5js5sm8ye2hh0v.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "hiphop-tamizhas-return-of-the-dragon-machi-home-editon-live-in-coimbatore-sep8-2024"
        }
    },
    {
        title: "Lights Out!",
        subtitle: "Indian Racing Fest",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470565/zlpsl5qk2nsaxgbtjxup.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "indian-racing-festival-2024-chennai-formula-racing-circuit"
        }
    },
    {
        title: "Karthik Raaja",
        subtitle: "And more legends, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470597/wh7dqd6bdfkyaleak7go.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "king-of-kings-2024-karthik-raaja-live-in-concert-coimbatore-oct19-2024"
        }
    },
    //visakhapatnam
    {
        title: "Top Lesi Poddi",
        subtitle: "Rockstar DSP, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039096/yn8obun5ohhi2e2albrt.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        title: "Chale Aana",
        subtitle: "See Armaan Malik, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039123/dmb784vsdxfcypyyvi5i.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Agam LIVE",
        subtitle: "In Hyderabad",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437217/elyap4jxtm1thelsirns.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "agam-live-in-hyderabad-sep21-2024"
        }
    },
    //vizag
    {
        title: "Top Lesi Poddi",
        subtitle: "Rockstar DSP, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039096/yn8obun5ohhi2e2albrt.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        title: "Chale Aana",
        subtitle: "See Armaan Malik, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039123/dmb784vsdxfcypyyvi5i.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Agam LIVE",
        subtitle: "In Hyderabad",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437217/elyap4jxtm1thelsirns.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "agam-live-in-hyderabad-sep21-2024"
        }
    }
];
export const placeholderQuickLinks = [
    {
        title: "Host an Event",
        subtitle: "Make your event live in under 5 mins",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "/list-your-events"
        }
    },
    {
        title: "Events this weekend",
        subtitle: "Events this weekend",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "everything",
            time_filter: "weekend"
        }
    }
];
export const mobilePlaceHolderLinks = [
    {
        title: "Download our app now!",
        subtitle: "Android & iOS",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "https://link.insider.in/app"
        }
    },
    ...placeholderQuickLinks
];
/**
     Key to be added for iOs on categoryBanners array:
     * hideForIOS : true if we don't want to show banner on iOs devices
     * showForIOS : true if we want to to show a different banenr for iOs device, create another object named categoryBannersForIOs with same format
     * * don't make both true
     * * No need to add above keys on categoryBannersForIOs, adding keys (if required) on original config array is sufficient.
     * * Don't add any key if not required
     */
export const categoryBannersForIOs = [];
export const categoryBanners = [
    //Category Banner
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723436451/xh6jxaahcqdrfvodqizj.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723436412/j24tizflg1is8twrg1pm.jpg",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "zamna-festival-mumbai-sep28-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724416830/wkxkhdnmf7bdetttqdnz.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724416844/bb3t5qdavzewmbhgbton.jpg",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "casa-bacard-on-tour-ft-talwiinder"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724416980/e8ror2jwpfqzgiwoojqi.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724416971/qgulgxyaajl3epjccfbw.jpg",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "sajanka-live-at-sunburn-union-sep22-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1719659204/iuiufed8pkmqtibowphk.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1719659217/m5evxoxrlcsveasuoov2.jpg",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-pune-dec14-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724038794/rywf0y7mbwdji35fvali.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724038789/qk2e8kvuv1oalhzpicma.jpg",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "kamakshi-khanna-heartbreak-2020-tour-goa-aug30-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470437/arh8fzpdpjsqnrtfl5sd.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470433/fqus868pawbnvta5vl73.jpg",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "agam-live-in-hyderabad-sep21-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470498/fhfhwjxwgwfuy6uwftgr.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470492/x9wfuw6ngqcenjjplicl.jpg",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "return-of-the-dragon-machi-home-edition-sep21-2024-chennai"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1719659398/wfhchmb96er91xykqgec.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1719659410/yp1qmsqowdog6kvlwr8z.jpg",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "ishq-fm-grand-concert-shreya-ghoshal-live-kolkata-sep14-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470630/wuu23qcmrisypahtjcoi.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470646/uz9yoxwoyexmaijx63sy.jpg",
        includeCities: ["coimbatore"],
        link: {
            resource_type: "event",
            slug: "hiphop-tamizhas-return-of-the-dragon-machi-home-editon-live-in-coimbatore-sep8-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039161/wieerk3ojaqv5auxigy6.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039166/ccvsttnb57ddne4x0bju.jpg",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    }
];
export const showcase = [
    // Type B
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437156/tegvlohjwrzfuftqo44o.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437160/yhdr4vaqmlnjjemq9ij1.jpg",
        categoryIcon: <Music />,
        heading: "PCRC LIVE!",
        subheading: "Enter the musical ‘Portrait Of A Time’ with Peter Cat Recording Co.",
        caption: "The band is coming to your city with their ‘Good Luck Beta’ tour! Brace yourselves for a mind-blowing performance and fresh new tracks!",
        cta: "Book Now",
        includeCities: ["goa"],
        cardListType: "schedule-card",
        tag: "good-luck-beta-tour",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/pcrc-goodluckbeta24"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1721397907/cqr663zbhue2rh8veqhm.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1721397901/epcrsosvgn9f8uoo1nrg.jpg",
        categoryIcon: <Music />,
        heading: "KING & Friends LIVE",
        subheading: "Watch KING LIVE in your city!",
        caption: "KING embarks on his 'Monopoly Moves' Album launch tour across India! Watch him perform his new album LIVE.",
        cta: "Book Now",
        includeCities: ["mumbai", "hyderabad", "kolkata", "pune"],
        cardListType: "schedule-card",
        tag: "king-monopoly-moves-2024-album-listening-party",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/microsite/king-monopoly-moves-2024-album-listening-party"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1722742997/exoixzrbnfumfmgmicrz.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1722742991/mgfcfuifxo0txghfquv4.jpg",
        categoryIcon: <Music />,
        heading: "Welcome to the Jungle",
        subheading: "ZAMNA is coming to India!",
        caption: "The internationally acclaimed ZAMNA Festival is making its debut in India, and the excitement is off the charts.",
        cta: "Book Now",
        includeCities: ["delhi"],
        cardListType: "schedule-card",
        tag: "zamna-festival-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/microsite/zamna-india-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225118/wmv93vxzby6gzui30nsh.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225124/c6ej2247yykzstpwb3md.jpg",
        categoryIcon: <Music />,
        heading: "Hip-hop Adhi's homecoming!",
        subheading: "One of Tamil Hip-hop's greatest is on tour",
        caption: "Tamil folk arts seamlessly blended into high energy performances and music. Are you ready for an  unforgettable spectacle?",
        cta: "Book Now",
        includeCities: ["chennai", "coimbatore"],
        cardListType: "schedule-card",
        tag: "hiphop-tamizhas-return-of-the-dragon-machi-home-edition",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "go",
            slug: "hiphop-tamizhas-return-of-the-dragon-machi-home-edition"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    //type A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1722015957/e9jp3rw16bhd0fsgsba4.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1722015937/ffhvilf7vvwf6godzxqc.jpg",
        categoryIcon: <Experiences />,
        heading: "Step into Van Gogh World",
        subheading: "Where the paintings of one history's most well know artist will come alive!",
        caption: "From August 2nd at the Nexus Elante, Chandigarh - witness Van Gogh's work in full brilliance as 20k Lumens projectors illuminate an immersive showcase.",
        cta: "Buy Now",
        includeCities: ["chandigarh"],
        link: {
            resource_type: "event",
            slug: "the-real-van-gogh-immersive-experience-chandigarh"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225442/hy0evrvrfjqtpjcst8na.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225439/fijvxhp4z4s7gsrtaeo2.jpg",
        categoryIcon: <Music />,
        heading: "First time in Vizag!",
        subheading: "Catch Armaan Malik LIVE this November!",
        caption: "Known for his soulful voice and chart-topping hits - from romantic ballads to foot-tapping numbers, experience the magic of his music up close and personal.",
        cta: "Buy Now",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    // Type C
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724419593/mhqnt0yiqu3igm0ft2yt.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724419589/ocvggrt97xzmjwme6scm.jpg",
        categoryIcon: <Cricket />,
        heading: "Karnataka's T20 Crown",
        subheading: "Shriram Capital Maharaja Trophy KSCA T20's 2024 season",
        caption: "Feel the pulse of the crowd as local heroes take the field, showcasing the best of Karnataka's cricketing prowess. Witness the #AsliVibeStadiumSide!",
        cta: "Book Now",
        includeCities: ["bengaluru"],
        cardListType: "small-event-card",
        tag: "maharaja-trophy-ksca-t20-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "article",
            slug: "maharaja-trophy-ksca-t20"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    }
];
