/** @jsx jsx */
import { jsx, css } from "@emotion/core";
// components
import { PageBanner } from "./PageBanner";
// helpers
import { capitalizeFirstLetter } from "../helpers/constants";
import { colors } from "../styles/common";
const KeywordGoOut = `
	font-weight: 700;
	color: ${colors.paytmBlue};
`;
const BannerEmptyState = (props) => (<PageBanner pageModifier="anchor">
		<span css={css(KeywordGoOut)}>Paytm Insider</span> helps you discover interesting events, travel & food in{" "}
		{capitalizeFirstLetter(props.selectedCity)}
	</PageBanner>);
export default BannerEmptyState;
